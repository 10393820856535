/* Thumb Carousel */

.carousel-slider {
  #carousel {
    margin: 0;
  }

  #thumbcarousel {
    margin: 10px 0 0 0;
    padding: 0;

    .carousel-item {
      text-align: center;

      .thumb {
        width: 100%;
        margin: 0 10px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        max-width: 100px;

        &:hover {
          border-color: #e8ebf3;
        }
      }
    }
  }

  .carousel-item img {
    width: 100%;
    height: auto;
  }
}

.carousel-control {
  &:active, &:focus, &:hover {
    color: #333;
  }
}

.carousel-caption, .carousel-control .fa {
  font: normal normal normal 30px/26px FontAwesome;
}

#basicSlider img {
  width: 100%;
}

#thumbcarousel {
  .carousel-control-prev {
    left: 0;
    background: $black-8;
  }

  .carousel-control-next {
    right: 0;
    background: $black-8;
  }
}

.owl-carousel button.owl-dot {
  background: rgba(105, 99, 255, 0.3);
  opacity: 7;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-nav button:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098d1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.carousel {
  position: relative;

  &.pointer-event {
    touch-action: pan-y;
  }
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;

  &.active {
    display: block;
  }
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade {
  .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;

    &.active {
      z-index: 1;
      opacity: 1;
    }
  }

  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
  }

  .active {
    &.carousel-item-left, &.carousel-item-right {
      z-index: 0;
      opacity: 0;
      transition: 0s 0.6s opacity;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active {
    &.carousel-item-left, &.carousel-item-right {
      transition: none;
    }
  }
}

.carousel-control-prev, .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: $white;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev {
  &:hover, &:focus {
    color: $white;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
}

.carousel-control-next {
  &:hover, &:focus {
    color: $white;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;

  li {
    box-sizing: content-box;
    flex: 0 1 auto;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: $white;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease;
  }

  .active {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $white;
  text-align: center;
  
  h5, p{
    font-family: "Roboto", sans-serif !important;
  }
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-image: none;
  display: block;
  width: auto;
  height: auto;
}

.carousel-control-prev-icon svg, .carousel-control-next-icon svg {
  width: 48px;
  height: 48px;
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}

.dashboard-carousel .btn-icons {
  display: inline-block;
  background: #eff2f6 !important;
  background: no-repeat 50% / 100% 100%;
  text-align: center;
  line-height: 15px;
  margin: 0 auto;
  align-items: center;
  padding: 0.375rem 0.75rem;
  min-height: inherit;

  i {
    font-size: 15px;
    margin: 0 auto;
    text-align: center;
  }
}